@charset "UTF-8";
@font-face {
  font-family: "icons";
  src: url("/assets/fonts/iconfont/icons.eot");
  src: url("/assets/fonts/iconfont/icons.eot?#iefix") format("eot"), url("/assets/fonts/iconfont/icons.woff") format("woff"), url("/assets/fonts/iconfont/icons.ttf") format("truetype"), url("/assets/fonts/iconfont/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*============================================================
  mainImg
============================================================*/
.mainImg {
  margin: -100px 0 0;
  position: relative;
  background: #35353c;
}
.mainImg_bg {
  position: relative;
}
.mainImg_bg > img, .mainImg_bg > video, .mainImg_bg > iframe {
  width: 100%;
  vertical-align: bottom;
  position: relative;
  z-index: 1;
}
.mainImg_vimeo {
  aspect-ratio: 1000/527.34;
  object-fit: contain;
}
.mainImg_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.mainImg_loader:before, .mainImg_loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainImg_loader:before {
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.mainImg_loader:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.mainImg_footer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  z-index: 20;
}
.mainImg_youtube {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 30px;
}
.mainImg_youtube > a {
  display: block;
  text-decoration: none;
}
.mainImg_news {
  width: 1000px;
  background: rgba(53, 53, 60, 0.6);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 35px 40px;
}
.mainImg_item {
  width: calc(100% - 240px - 40px);
  margin: 0 40px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.mainImg_item > div, .mainImg_item > a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  text-decoration: none;
}
.mainImg_date {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.8;
}
.mainImg_cate {
  margin: 0 0 0 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  max-width: 200px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1.8;
}
.mainImg_name {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 0 0 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mainImg_more {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 auto;
}
.mainImg_more > a {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  width: 240px;
  padding: 0 20px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.mainImg_more > a:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  font-family: "icons";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1428571429rem;
  line-height: 0.88;
  vertical-align: 0;
  font-weight: normal;
}
.mainImg_scroll {
  width: 265px;
  text-align: right;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  position: absolute;
  left: 80px;
  bottom: -1px;
  z-index: 10;
  transform-origin: 0 0;
  transform: translateY(100%) rotate(-90deg);
}
.mainImg_scroll:before {
  content: "";
  display: block;
  height: 1px;
  width: 200px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
}
.mainImg_scroll:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 190px;
  background: #fff;
  transform: translateY(-50%);
  animation: scrAnime 2s cubic-bezier(0.85, 0, 0.15, 1) infinite;
}

@keyframes load8 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes scrAnime {
  0% {
    opacity: 0;
    transform: translate(0, -50%);
  }
  40% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  95% {
    opacity: 0;
    transform: translate(-190px, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-190px, -50%);
  }
}
@media screen and (max-width: 736px) {
  .mainImg {
    margin: -50px 0 0;
  }
  .mainImg:after {
    content: none;
  }
  .mainImg_footer {
    margin: -34px 0 0;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    display: block;
  }
  .mainImg_youtube {
    margin: 0;
    padding: 0 10px;
    text-align: right;
    line-height: 1;
  }
  .mainImg_youtube > a {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: normal;
  }
  .mainImg_news {
    width: auto;
    margin: 10px 0 0;
    padding: 10px;
    display: block;
  }
  .mainImg_item {
    margin: 0;
    width: auto;
  }
  .mainImg_item > div, .mainImg_item > a {
    margin-top: -5px;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-direction: row;
  }
  .mainImg_date {
    width: 5.5em;
    margin: 5px 10px 0 0;
  }
  .mainImg_cate {
    margin: 5px 10px 0 0;
    max-width: calc(100% - 5.5em - 20px);
  }
  .mainImg_name {
    margin: 5px 0 0;
  }
  .mainImg_more {
    margin: 10px 0 0;
    text-align: right;
  }
  .mainImg_more > a {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: normal;
  }
  .mainImg_scroll {
    display: none;
  }
}
/*============================================================
  sec01
============================================================*/
.sec01 {
  position: relative;
}
.sec01_msg {
  margin-top: -140px;
  margin-bottom: 120px;
  background: #e3e3e3;
}
.sec01_msg > span {
  width: 1080px;
  margin: 0 auto;
  padding: 6px 0;
  display: block;
  text-align: center;
  font-size: 1rem;
}
.sec01_bg {
  position: absolute;
  right: 50%;
  bottom: 0;
  margin-right: -540px;
}
.sec01_inner {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  padding-bottom: 100px;
}
.sec01_inner > :first-child {
  margin-top: 0;
}
.sec01_title {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 3.4285714286rem;
}
.sec01_title > span {
  flex-grow: 0;
  flex-shrink: 0;
}
.sec01_title:after {
  flex-grow: 1;
  flex-shrink: 1;
  content: "";
  display: block;
  height: 1px;
  background: #35353c;
  margin-left: 130px;
}
.sec01_ja {
  margin: 30px 0 0;
  font-size: 1.7142857143rem;
  font-weight: bold;
}
.sec01_txt {
  margin: 50px 0 0;
  width: 520px;
}
.sec01_cooperation {
  font-size: 1.2857142857rem;
}
.sec01_cooperation > b {
  font-size: 3rem;
  line-height: 1.14;
  font-family: "Roboto", sans-serif;
}
.sec01_cooperation > span {
  font-size: 1.7142857143rem;
}
.sec01_cooperation > span + b {
  margin-left: 15px;
}
.sec01_more {
  margin: 70px 0 0;
  width: 340px;
}

@media screen and (max-width: 736px) {
  .sec01_msg {
    margin: -40px -10px 40px;
  }
  .sec01_msg > span {
    padding: 5px 10px;
    width: auto;
    text-align: left;
  }
  .sec01_bg {
    display: none;
    max-width: 100vw;
    margin: 0;
    right: 0;
    left: 0;
    bottom: 80px;
    opacity: 0.5;
  }
  .sec01_bg img {
    max-width: 100%;
  }
  .sec01_inner {
    padding: 0 5px;
    width: auto;
  }
  .sec01_title {
    display: block;
    font-size: calc((100vw - 20px) / 10);
  }
  .sec01_title:after {
    content: none;
  }
  .sec01_ja {
    margin: 10px 0 0;
    font-size: calc((100vw - 20px) / 20);
  }
  .sec01_txt {
    width: auto;
    margin: 30px 0 0;
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;
  }
  .sec01_cooperation {
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;
  }
  .sec01_more {
    width: auto;
  }
}
/*============================================================
sec02
============================================================*/
.sec02 {
  margin: 200px 0 0;
  position: relative;
}
.sec02:before {
  content: "";
  display: block;
  background: #35353c;
  height: 100%;
  width: calc((100% - 1080px) / 2 + 1080px + 100px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.sec02:after {
  content: "";
  display: block;
  background: #f1f0ec;
  width: calc((100% - 1080px) / 2 + 740px);
  position: absolute;
  top: 0;
  bottom: -350px;
  right: 0;
  z-index: 1;
}
.sec02_header {
  position: relative;
  z-index: 10;
  width: 1080px;
  margin: 0 auto;
  padding: 120px 0;
  background: #35353c;
  display: grid;
  grid-template-columns: 340px 1fr;
  column-gap: 40px;
  color: #fff;
}
.sec02_title {
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.2em;
  line-height: 1.14;
}
.sec02_ja {
  grid-column: 1;
  grid-row: 2;
  margin: 10px 0 0;
  font-size: 1.2857142857rem;
}
.sec02_headerTxt {
  grid-column: 2;
  grid-row: 1/4;
  margin: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
}
.sec02_headerBtn {
  grid-column: 1;
  grid-row: 3;
  margin: 0;
  margin: 60px 0 0;
}
.sec02_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px 0;
  position: relative;
  z-index: 10;
  color: #fff;
}
.sec02_item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  gap: 0;
}
.sec02_item > a {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  gap: 0;
  text-decoration: none;
  color: inherit;
  position: relative;
}
.sec02_item-filter .sec02_imgOff:before,
.sec02_item-filter .sec02_imgOn:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.sec02_item-wide {
  grid-column: span 2;
}
.sec02_imgOff {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sec02_imgOff img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sec02_imgOn {
  display: none;
  position: relative;
}
.sec02_itemInner {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  gap: 50px 0;
  position: relative;
  padding: 60px 30px;
}
.sec02_name {
  margin: 0;
  font-size: calc(25vw / 16);
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2em;
  text-align: center;
}
.sec02_name > span {
  font-size: calc(25vw / 24);
  letter-spacing: normal;
}
.sec02_txt {
  margin: 0;
}
.sec02_btn {
  margin: 0;
}
.sec02_btn > .btn {
  font-size: calc(25vw / 30);
}

@media screen and (min-width: 737px) {
  .sec02_item > a {
    transition: all 0.4s;
  }
  .sec02_item > a:hover {
    opacity: 1;
  }
  .sec02_item > a:hover .sec02_imgOn {
    opacity: 1;
    max-height: 116.67%;
    top: -8.34%;
    bottom: -8.34%;
    box-shadow: 0px 8px 21.6px 2.4px rgba(0, 0, 0, 0.4);
  }
  .sec02_imgOn {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    max-height: 100%;
    box-shadow: 0px 8px 21.6px 2.4px rgba(0, 0, 0, 0);
    transition: all 0.5s;
  }
  .sec02_imgOn img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .sec02_btn > .btn {
    transition: all 0.4s;
  }
  .sec02_btn > .btn:hover {
    border-color: transparent;
  }
}
@media screen and (max-width: 736px) {
  .sec02 {
    margin: 50px 0 0;
  }
  .sec02:before, .sec02:after {
    content: none;
  }
  .sec02_header {
    margin: 0 -10px;
    padding: 30px 15px;
    display: block;
    width: auto;
  }
  .sec02_header > :first-child {
    margin-top: 0;
  }
  .sec02_title {
    font-size: 2rem;
  }
  .sec02_ja {
    margin: 0;
    font-size: 1.2307692308rem;
  }
  .sec02_headerTxt {
    margin: 30px 0 0;
  }
  .sec02_headerBtn {
    margin: 30px 0 0;
  }
  .sec02_list {
    display: block;
    margin: 0 -10px;
  }
  .sec02_item {
    display: block;
    margin: 2px 0 0;
    overflow: hidden;
  }
  .sec02_item > a {
    display: block;
  }
  .sec02_imgOff {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .sec02_imgOff img {
    object-fit: cover;
  }
  .sec02_itemInner {
    padding: 20px 15px;
    display: block;
    position: relative;
  }
  .sec02_name {
    font-size: calc((100vw - 20px) / 15);
  }
  .sec02_name > span {
    font-size: calc((100vw - 20px) / 24);
  }
  .sec02_txt {
    padding: 0;
    margin: 5px 0 0;
    font-size: 0.7692307692rem;
  }
  .sec02_btn {
    padding: 0;
    margin: 15px 0 0;
  }
  .sec02_btn > .btn {
    font-size: calc((100vw - 20px) / 28);
  }
}
/*============================================================
sec03
============================================================*/
.sec03 {
  position: relative;
  z-index: 3;
  margin: 440px 0 0;
}
.sec03:before {
  content: "";
  display: block;
  background: #f1f0ec;
  position: absolute;
  top: -200px;
  right: 0;
  bottom: -400px;
  width: calc((100% - 1080px) / 2 + 340px);
}
.sec03_inner {
  width: calc(100% - (100% - 1080px) / 2);
  margin: 0 0 0 calc((100% - 1080px) / 2);
  display: grid;
  grid-template-columns: 440px 1fr;
  column-gap: 100px;
  grid-auto-flow: column;
  position: relative;
}
.sec03_title {
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.2em;
  line-height: 1.14;
}
.sec03_ja {
  grid-column: 1;
  grid-row: 2;
  margin: 10px 0 0;
  font-size: 1.2857142857rem;
}
.sec03_txt {
  grid-column: 1;
  grid-row: 3;
  margin: 50px 0 0;
}
.sec03_slider {
  overflow: hidden;
  margin: -200px 0 0;
  grid-column: 2;
  grid-row: 1/5;
  position: relative;
}
.sec03_views {
  min-width: 810px;
}
.sec03_list {
  margin: 0 -15px;
}
.sec03_list .slick-slide {
  width: 840px;
  padding: 0 15px;
}
.sec03_view {
  margin: 0;
  position: relative;
  height: 470px;
  background: #f1f0ec;
}
.sec03_view > div, .sec03_view > a {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.sec03_view img {
  margin: 0 auto;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sec03_view figcaption {
  margin: 0;
  padding: 90px 20px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  font-size: 1.2857142857rem;
  font-weight: bold;
  color: #fff;
}
.sec03_arrow {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #35353c;
  text-decoration: none;
  position: absolute;
  bottom: -120px;
}
.sec03_arrow:before {
  content: none;
  font-family: "icons";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  line-height: 1;
}
.sec03_arrow-prev {
  left: 395px;
}
.sec03_arrow-prev:before {
  content: "";
}
.sec03_arrow-next {
  left: 475px;
}
.sec03_arrow-next:before {
  content: "";
}
.sec03_arrow.slick-disabled {
  opacity: 0.2 !important;
}
.sec03_counter {
  margin: 0;
  position: absolute;
  top: 560px;
  right: calc(100% - 360px);
  transform: translateY(-50%);
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #909090;
  font-size: 1.2857142857rem;
}
.sec03_counterCurrent {
  color: #35353c;
}
.sec03_btn {
  grid-column: 1;
  grid-row: 4;
  margin: 80px 0 0;
  width: 340px;
}

@media screen and (max-width: 736px) {
  .sec03 {
    margin: 0 -10px;
    padding: 50px 15px 0;
  }
  .sec03:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 30px;
    width: auto;
  }
  .sec03_inner {
    width: auto;
    display: block;
    margin: 0;
  }
  .sec03_inner > :first-child {
    margin-top: 0;
  }
  .sec03_title {
    font-size: 2rem;
  }
  .sec03_ja {
    margin: 0;
    font-size: 1.2307692308rem;
  }
  .sec03_txt {
    margin: 30px 0 0;
  }
  .sec03_slider {
    margin: 30px 0 0;
  }
  .sec03_views {
    min-width: 0;
  }
  .sec03_list {
    margin: 0;
  }
  .sec03_list .slick-slide {
    width: auto;
    padding: 0;
  }
  .sec03_list .slick-slide > div {
    line-height: 1;
    vertical-align: bottom;
  }
  .sec03_view {
    height: auto;
  }
  .sec03_view > a, .sec03_view > div {
    height: calc(470/810 * (100vw - 20px));
  }
  .sec03_view figcaption {
    padding: 8px 10px;
    font-size: 1rem;
  }
  .sec03_arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
    bottom: -50px;
  }
  .sec03_arrow-prev {
    left: 0;
  }
  .sec03_arrow-next {
    left: auto;
    right: 0;
  }
  .sec03_counter {
    margin: 10px 50px 0;
    height: 40px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    position: static;
    text-align: center;
    transform: none;
  }
  .sec03_btn {
    margin: 30px 0 0;
    width: auto;
  }
}
/*============================================================
  sec04
============================================================*/
.sec04 {
  margin: 240px 0 0;
  padding: 0 40px;
  position: relative;
  z-index: 3;
}
.sec04_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.sec04_bg img {
  object-fit: cover;
  width: 100%;
  height: 640px;
}
.sec04_header {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 110px 0 130px;
}
.sec04_title {
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.2em;
  line-height: 1.14;
}
.sec04_ja {
  grid-column: 1;
  grid-row: 2;
  margin: 10px 0 0;
  font-size: 1.2857142857rem;
}
.sec04_headerTxt {
  grid-column: 2;
  grid-row: 1/3;
  align-self: center;
  margin: 0;
}
.sec04_contents {
  position: relative;
  max-width: calc(1920px - 80px);
  margin: 0 auto;
  padding: min(120px, 500 / 1920 * (100% - 1080px)) max(60px, 60 / 1920 * (100% - 1080px)) min(160px, 500 / 1920 * (100% - 1080px));
  background: #f1f0ec;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
}
.sec04_item {
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
}
.sec04_num {
  margin: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.sec04_num:before {
  order: 1;
  flex-grow: 0;
  flex-shrink: 0;
  content: "";
  height: 1px;
  width: 40px;
  background: #35353c;
  margin: 0 5px 0 10px;
}
.sec04_num > b {
  order: 0;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.7142857143rem;
}
.sec04_num > span {
  order: 2;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
}
.sec04_img {
  margin: 35px 0 0;
}
.sec04_name {
  margin: 30px 0 0;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5;
}
.sec04_txt {
  margin: 25px 0 0;
}
.sec04_btn {
  max-width: 340px;
  margin: 55px 0 0;
}

@media screen and (max-width: 736px) {
  .sec04 {
    margin: 40px -10px 0;
    padding: 0 15px;
  }
  .sec04_bg img {
    height: 100vw;
  }
  .sec04_header {
    width: auto;
    display: block;
    padding: 40px 0;
  }
  .sec04_title {
    font-size: 2rem;
  }
  .sec04_ja {
    margin: 0;
    font-size: 1.2307692308rem;
  }
  .sec04_headerTxt {
    margin: 30px 0 0;
  }
  .sec04_contents {
    margin: 0 10px;
    padding: 30px 15px;
    display: block;
  }
  .sec04_contents > :first-child {
    margin-top: 0;
  }
  .sec04_item {
    margin: 40px auto 0;
    max-width: 520px;
    display: block;
  }
  .sec04_item > :first-child {
    margin-top: 0;
  }
  .sec04_img {
    margin: 10px 0 0;
  }
  .sec04_name {
    margin: 15px 0 0;
    font-size: 1.8461538462rem;
  }
  .sec04_txt {
    margin: 10px 0 0;
  }
  .sec04_btn {
    margin: 20px 0 0;
    max-width: none;
  }
}
/*============================================================
  sec06
============================================================*/
.sec06 {
  margin: 160px 0 0;
  color: #fff;
  position: relative;
}
.sec06_bg {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sec06_bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sec06_inner {
  position: relative;
  width: 1080px;
  margin: 0 auto;
  padding: 120px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 40px;
}
.sec06_title {
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.2em;
  line-height: 1.14;
}
.sec06_ja {
  grid-column: 1;
  grid-row: 2;
  margin: 10px 0 0;
  font-size: 1.2857142857rem;
}
.sec06_lead {
  grid-column: 1;
  grid-row: 3;
  margin: 50px 0 0;
  font-weight: bold;
  font-size: 1.7142857143rem;
}
.sec06_txt {
  grid-column: 1;
  grid-row: 4;
  margin: 40px 0 0;
}
.sec06_infos {
  grid-column: 2;
  grid-row: 1/5;
  align-self: center;
}
.sec06_infoList {
  display: grid;
  grid-template-columns: repeat(3, auto);
  border: 2px solid #fff;
  padding: 30px 0;
}
.sec06_info {
  margin: 0;
  padding: 0 20px;
  text-align: center;
}
.sec06_info > dd {
  font-size: 1.7142857143rem;
  line-height: 1.17;
}
.sec06_info > dd > b {
  font-size: 3rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.sec06_info > dd > span {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  font-size: 0.8571428571rem;
  transform: translateY(-0.3em);
}
.sec06_info + .sec06_info {
  border-left: 2px solid #fff;
}
.sec06_btn {
  grid-column: 1;
  grid-row: 5;
  max-width: 340px;
  margin: 40px 0 0;
}

@media screen and (max-width: 736px) {
  .sec06 {
    margin: 40px -10px 0;
  }
  .sec06_inner {
    padding: 40px 15px;
    display: block;
    width: auto;
  }
  .sec06_title {
    font-size: 2rem;
  }
  .sec06_ja {
    margin: 0;
    font-size: 1.2307692308rem;
  }
  .sec06_lead {
    margin: 30px 0 0;
    font-size: 1.3846153846rem;
  }
  .sec06_txt {
    margin: 30px 0 0;
  }
  .sec06_infos {
    margin: 30px 0 0;
  }
  .sec06_infoList {
    display: block;
    padding: 0 10px;
  }
  .sec06_info {
    padding: 10px 0;
  }
  .sec06_info + .sec06_info {
    border-left: none;
    border-top: 2px solid #fff;
  }
  .sec06_btn {
    margin: 30px 0 0;
    max-width: none;
  }
}